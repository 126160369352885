<!--
 * @Author: Akko
 * @Description: 工作经验
-->
<template>
    <div>
        <el-form ref="userForm" v-loading="loading" :model="userForm" :rules="rules" label-width="140px">
            <!-- 主申请人工作经历 -->
            <div class="card-container">
                <div class="card-heard">主申请人工作经历 (【获得学位证之后】的【全职】就业记录，兼职不需要填写)</div>
                <div v-for="(domain, idx) in userForm.workList" :key="domain.id" class="work-box">
                    <div class="workr-heard">
                        <div />
                        <div class="del-cur" @click="removeMember(domain)">删除</div>
                    </div>
                    <el-row class="block-top">
                        <!-- 左 -->
                        <el-col :span="12">
                            <div class="one-input">
                                <el-tooltip placement="top">
                                    <div slot="content">须与推荐信或离职证明上所盖公<br />章的单位名称完全相同</div>
                                    <img class="sigh-img" style="left: 38px;bottom: 27px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>

                                <el-form-item label="雇主名称:" :prop="'workList.'+idx+'.companyName'" :rules="[{required: true,message: '请输入雇主名称',trigger: 'blur'}]">
                                    <el-input v-model.trim="domain.companyName" maxlength="100" class="full-width" placeholder="请输入准确的全称" clearable />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="雇主所属机构性质:" :prop="'workList.'+idx+'.industry'" :rules="[{required: true,message: '请选择雇主所属机构性质',trigger: 'change'}]">
                                    <el-select v-model="domain.industry" class="full-width" placeholder="请选择" clearable>
                                        <el-option v-for="(item,index) in subsidiaryOrganList" :key="index" :label="item" :value="item" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="domain.industry.indexOf('其他')!=-1" class="one-input" style="margin-top:-16px;">
                                <el-form-item label="" :prop="'workList.'+idx+'.industrys'" :rules="[{required: true,message: '请输入雇主所属机构性质',trigger: 'blur'}]">
                                    <el-input v-model.trim="domain.industrys" maxlength="30" class="full-width" style="margin-top:16px" placeholder="请备注" clearable />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="入职年月:" :prop="'workList.'+idx+'.entryTime'" :rules="[{required: true,message: '请选择入职年月',trigger: 'change'}]">
                                    <el-date-picker v-model="domain.entryTime" class="full-width" value-format="yyyy-MM" :picker-options="pickerBeginDateBefore" type="month" placeholder="选择入职时间" @change="(value) => pikerStartTime(value, idx)" />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="离职年月:" :prop="'workList.'+idx+'.departureTime'" :rules="[{required: true,message: '请选择离职年月',trigger: 'change'}]">
                                    <el-date-picker v-if="!domain.departureTimes" v-model="domain.departureTime" value-format="yyyy-MM" :picker-options="pickerBeginDateBefore" class="input-width" type="month" placeholder="选择离职时间" @change="(value) => pikerEndTime(value, idx)" />
                                    <el-checkbox v-model="domain.departureTimes" style="margin-top: -16px;" label="至今" @change="endTimeNow($event,domain)">至今</el-checkbox>
                                </el-form-item>
                            </div>
                        </el-col>
                        <!-- 右 -->
                        <el-col :span="12">
                            <div class="one-input">
                                <el-form-item label="工作地点:" :prop="'workList.'+idx+'.locationAboard'" :rules="[{required: true, message: '请选择工作地点',trigger: 'change'}]">
                                    <div class="flex-center">
                                        <el-radio-group v-model="domain.locationAboard" style="margin-top: 9px;" @change="changeLocationAboard($event,domain)">
                                            <el-radio :label="0">中国</el-radio>
                                            <el-radio :label="1">国外</el-radio>
                                        </el-radio-group>
                                        <el-form-item v-if="domain.locationAboard===0" :key="1" label="" :prop="'workList.'+idx+'.location.area'" class="margin-zero" :rules="[{required: true,message: '请选择城市',trigger: 'change'}]">
                                            <CitySelect v-model="domain.location.area" class="city-inline" placeholder="请选择城市" />
                                        </el-form-item>
                                        <el-form-item v-if="domain.locationAboard===1" :key="2" label="" :prop="'workList.'+idx+'.location.foreign'" class="margin-zero" :rules="[{required: true,message: '请输入城市',trigger: 'blur'}]">
                                            <div class="city-input-width city-inline">
                                                <el-input v-model.trim="domain.location.foreign" placeholder="请输入城市" maxlength="50" clearable />
                                            </div>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="职位:" :prop="'workList.'+idx+'.position'" :rules="[{required: true,message: '请输入职位',trigger: 'blur'}]">
                                    <el-input v-model.trim="domain.position" class="full-width" maxlength="20" placeholder="请输入" clearable />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-tooltip placement="top">
                                    <div slot="content">高层管理岗： 机构管治阶层最上层的人士，对机构的整体绩效负责<br />中层管理岗：最高管理层与基层管理人员之间的中层管理人员<br />低层管理岗：负责监督机构日常操作员工的管理人员<br />专业人士 ：必须具备高等学历或专门训练才能胜任有关职位的人士<br />半专业人士： 为专业人员提供技术性支持的人士</div>
                                    <img class="sigh-img" style="left: 11px;bottom: 27px;" src="@/assets/img/hover.png" alt="">
                                </el-tooltip>
                                <el-form-item label="职位水平类别:" :prop="'workList.'+idx+'.professionalLevel'" :rules="[{required: true,message: '请选择职位水平类别',trigger: 'change'}]">
                                    <el-select v-model="domain.professionalLevel" class="full-width" placeholder="请选择职位水平类别" clearable>
                                        <el-option v-for="(proitem,proindex) in postList" :id="proindex" :key="proitem.id" :label="proitem.tit" :value="proitem.idx" />
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="domain.professionalLevel===6" class="one-input">
                                <el-form-item label="" :prop="'workList.'+idx+'.professionalOther'" :rules="[{required: true,message: '请输入职位水平类别',trigger: 'blur'}]">
                                    <el-input v-model.trim="domain.professionalOther" class="input-width" placeholder="请输入职位水平类别" maxlength="20" clearable />
                                </el-form-item>
                            </div>
                            <div class="one-input">
                                <el-form-item label="职责性质:" :prop="'workList.'+idx+'.duty'" :rules="[{required: true,message: '请输入职责性质',trigger: 'blur'}]">
                                    <el-input v-model.trim="domain.duty" class="full-width" placeholder="简单概况您的工作内容（20字以内）" maxlength="20" clearable />
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="add-people" @click="addMember">+新增工作经验</div>
            </div>
        </el-form>

        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="goPre">上一步</el-button>
            <el-button v-if="(user.baseSchedule ) < 5" class="person-btn btn-blue" :loading="isSave" @click="saveForm('save')">保存</el-button>
            <el-button class="person-btn btn-blue" :loading="isNext" @click="saveForm('next')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { saveWork } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
import CitySelect from "@/components/CitySelect/CitySelect";
export default {
    name: "Work",
    components: {
        CitySelect,
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            experienceList: [
                { tit: "获得最低学位证以后，拥有不超过2年工作经验", idx: 5 },
                { tit: "获得最低学位证以后，拥有2年以上的工作经验", idx: 1 },
                { tit: "获得最低学位证以后，拥有5年以上的工作经验", idx: 2 },
                {
                    tit: "获得最低学位证以后，拥有5年以上的工作经验，其中2年以上担任高级职位",
                    idx: 3,
                },
                {
                    tit: "获得最低学位证以后，拥有10年以上的工作经验，其中5年以上担任高管领导职位",
                    idx: 4,
                },
            ], //工作经验
            clearanceList: [
                "废物处理专家",
                "资产管理专才",
                "海运保险专才",
                "精算师",
                "金融科技专才",
                "数据科学家及网络安全专家",
                "创新及科技专家",
                "造船师",
                "轮机工程师及船舶总管",
                "创意产业专才",
                "争议解决专才及业务交易律师",
            ], //人才清单
            postList: [
                { tit: "半专业人士", idx: 5 },
                { tit: "专业人士", idx: 4 },
                { tit: "低层管理岗", idx: 3 },
                { tit: "中层管理岗", idx: 2 },
                { tit: "高层管理岗", idx: 1 },
                { tit: "其他", idx: 6 },
            ], //职位水平
            subsidiaryOrganList: [
                "个人独资企业",
                "合伙企业",
                "有限责任公司/股份有限公司",
                "跨国个人独资企业",
                "跨国合伙企业",
                "跨国有限责任公司/股份有限公司",
                "上市公司",
                "上市跨国公司",
                "政府机构",
                "半公营企业",
                "国家/政府间组织",
                "志愿/慈善/宗教团体",
                "教育机构",
                "其他（请备注）",
            ],
            userForm: {
                option: "",
                workList: [
                    {
                        //工作经历
                        orderId: 0,
                        companyName: "", //雇主名称
                        duty: "", //职责性质
                        entryTime: "", //入职时间
                        departureTime: "", //离职时间
                        industry: "", //雇主所属机构性质
                        location: {
                            //工作地点
                            area: [],
                            foreign: "",
                        },
                        locationAboard: "", //工作地点国内国外  0-国内 1-国外
                        professionalLevel: "", //职位水平(1.高层管理岗2.中层管理岗3.低层管理岗4.专业人士5.半专业人士6.其他)
                        professionalOther: "", //职位水平其他输入
                        position: "", //职位
                        remark: "", //备注
                    },
                ],
                order: {
                    id: 0,
                    workExperience: "", //工作经验 传数字
                    internationalExperience: "", //获得学位证以后，拥有2年以上【国际】工作经验 2-不满足 1-满足
                    talentList: {
                        //您是否符合人才清单
                        value: "", // 数字后面的值
                        answer: "", // 0-不符合 1-符合
                    },
                },
            },
            rules: {
                "order.workExperience": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "order.internationalExperience": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                "order.talentList.value": [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
            },
            loading: false,
            isSave: false, //防抖loading
            isNext: false,
        };
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 刷新数据
        dataEcho() {
            this.loading = true;
            this.getInfoData(this.$route.params.id).then((res) => {
                this.loading = false;
                if (res.code === 200) {
                    if (res.data.workList.length === 0) {
                        this.userForm.workList = [
                            {
                                orderId: this.$route.params.id,
                                companyName: "",
                                duty: "",
                                entryTime: "",
                                departureTime: "",
                                industry: "",
                                location: {
                                    area: [],
                                    foreign: "",
                                },
                                locationAboard: 0,
                                professionalLevel: "",
                                professionalOther: "",
                                position: "",
                                remark: "",
                            },
                        ];
                    } else {
                        res.data.workList.forEach((ele) => {
                            // 判断是否备注机构
                            if (
                                this.subsidiaryOrganList.indexOf(
                                    ele.industry
                                ) == -1 &&
                                ele.industry !== ""
                            ) {
                                ele.industrys = ele.industry;
                                ele.industry = "其他（请备注）";
                            }
                            // 判断是否至今
                            if (ele.departureTime === "至今") {
                                ele.departureTimes = true;
                                // ele.departureTime = "2021-04-02";
                                ele.departureTime = this.$utils.today();
                            } else {
                                ele.departureTimes = false;
                            }
                            // 判断职位水平类别
                            if (ele.professionalLevel === 0) {
                                ele.professionalLevel = "";
                            }
                        });
                        this.userForm.workList = res.data.workList;
                    }
                    this.userForm.order.id = res.data.order.id;
                    this.userForm.order.workExperience =
                        res.data.order.workExperience === 0
                            ? ""
                            : res.data.order.workExperience;
                    this.userForm.order.internationalExperience =
                        res.data.order.internationalExperience === 0
                            ? ""
                            : res.data.order.internationalExperience;
                    this.userForm.order.talentList =
                        res.data.order.talentList === null
                            ? {
                                  value: "",
                                  answer: "",
                              }
                            : res.data.order.talentList;
                }
            });
        },
        // 禁止入职年月乱选
        pikerStartTime(e, idx) {
            let entryTime = e === null ? "" : e;
            let departureTime = this.userForm.workList[idx].departureTime;
            if (departureTime !== "" && departureTime !== null) {
                // let result = Date.parse(departureTime.trim().replace(/-/g, '-')) > Date.parse(entryTime.trim().replace(/-/g, '-'));
                let result =
                    new Date(departureTime).getTime() >
                    new Date(entryTime).getTime();
                if (!result) {
                    this.userForm.workList[idx].entryTime = "";
                    this.$message.error("入职年月不得在离职年月之后");
                }
            }
        },
        // 禁止入职年月乱选
        pikerEndTime(e, idx) {
            let entryTime = this.userForm.workList[idx].entryTime;
            let departureTime = e === null ? "" : e;
            if (entryTime !== "" && entryTime !== null) {
                // let result = Date.parse(departureTime.trim().replace(/-/g, '/')) > Date.parse(entryTime.trim().replace(/-/g, '/'));
                let result =
                    new Date(entryTime).getTime() <
                    new Date(departureTime).getTime();
                if (!result) {
                    this.userForm.workList[idx].departureTime = "";
                    this.$message.error("离职年月不得在入职年月之前");
                }
            }
        },
        // 至今选择
        endTimeNow(e, item) {
            if (e) {
                // item.departureTime = "2021-04-02"; //占位
                item.departureTime = this.$utils.today();
            } else {
                item.departureTime = "";
            }
        },
        // 更改工作地点
        changeLocationAboard(e, item) {
            if (e === 0) {
                item.location.foreign = "";
            } else {
                item.location.area = [];
            }
        },
        // 新增工作经验
        addMember() {
            this.userForm.workList.push({
                //工作经历
                orderId: this.$route.params.id,
                companyName: "", //雇主名称
                duty: "", //职责性质
                entryTime: "", //入职时间
                departureTime: "", //离职时间
                industry: "", //雇主所属机构性质
                location: {
                    //工作地点
                    area: [],
                    foreign: "",
                },
                locationAboard: "", //工作地点国内国外  0-国内 1-国外
                professionalLevel: "", //职位水平(1.高层管理岗2.中层管理岗3.低层管理岗4.专业人士5.半专业人士6.其他)
                professionalOther: "",
                position: "", //职位
                remark: "", //备注
                key: Date.now(),
            });
        },
        // 移除工作经验
        removeMember(item) {
            var index = this.userForm.workList.indexOf(item);
            if (index !== -1) {
                this.userForm.workList.splice(index, 1);
            }
        },
        // 判断是保存还是下一步
        saveForm(txt) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            this.userForm.workList.forEach((ele) => {
                // 判断是否备注机构
                if (ele.industrys != undefined) {
                    ele.industry = ele.industrys;
                    delete ele.industrys;
                }
                // 判断是否至今
                if (ele.departureTimes === true) {
                    ele.departureTime = "至今";
                }
                delete ele.departureTimes;
            });
            // this.userForm.order.talentList.value === '0' ? this.userForm.order.talentList.answer = 0 : this.userForm.order.talentList.answer = 1;
            if (txt === "save") {
                this.isSave = true;
                this.userForm.option = "save";
                return this.hanledSaveQuestion(4);
            } else {
                this.userForm.option = "submit";
                this.$refs.userForm.validate((valid) => {
                    if (valid) {
                        let noPass = false; //不通過
                        for (let val of this.userForm.workList) {
                            // 校验出生城市国内
                            if (
                                val.locationAboard === 0 &&
                                val.location.area[0] === ""
                            ) {
                                this.$message.error("请完善工作地点");
                                noPass = true;
                                return false;
                            }
                        }
                        if (!noPass) {
                            this.isNext = true;
                            return this.hanledSaveQuestion(5);
                        }
                    } else {
                        return this.$message.error("请完善信息");
                    }
                });
            }
        },
        // 上一步
        goPre() {
            this.$router.replace({
                name: "ptep3",
            });
        },
        // 发起保存动作
        hanledSaveQuestion(parmas) {
            return new Promise((resolve, reject) => {
                saveWork(this.userForm).then((res) => {
                    this.isNext = false;
                    if (res.code === 200) {
                        // 切换步骤保存 下一步的时候才设置
                        if (this.userForm.option === "submit") {
                            this.saveUserData(parmas);
                            this.$message.success(res.data);
                            this.$router.replace({
                                name: "ptep" + parmas,
                            });
                        } else {
                            this.$message.success("保存成功！");
                            this.dataEcho();
                            this.isSave = false;
                        }
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.one-input {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .el-radio {
        margin-right: 16px;
    }
}

.del-cur {
    cursor: pointer;
}

.sigh-img {
    position: absolute;
}

.city-inline {
    display: inline-block;
    width: 164px;
}

.full-width {
    width: 300px;
}

.input-width {
    width: 232px;
    margin-right: 10px;
}

.work-box {
    border-bottom: 1px solid #dcdfe6;
    padding-bottom: 14px;

    .workr-heard {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 16px;
        color: #1890ff;
        height: 36px;
    }
}

.flex-center {
    display: flex;
    align-items: flex-start;
}

.margin-zero {
    margin-bottom: 0;
}

.add-people {
    width: 928px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #dcdfe6;
    color: #409eff;
    font-size: 14px;
    text-align: center;
    margin: 16px auto;
    cursor: pointer;
}

.experience-box {
    padding: 22px 0 0 16px;
    font-size: 14px;
    line-height: 20px;
    color: #606266;

    .experience-title {
        color: #303133;
        margin-bottom: 10px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 2px;
            color: red;
        }
    }

    .experience-des {
        color: #e6a23c;
        margin-bottom: 18px;

        a {
            color: #409eff;
            margin-left: 10px;
        }
    }

    .experience-radio {
        margin-left: 0;

        .el-radio {
            display: block;
            margin-bottom: 12px;
        }
    }

    .experience-radio-four {
        .el-radio {
            width: 22%;
            margin-bottom: 12px;
        }
    }
}
</style>
